import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { faAngleDown, faAngleUp, faEdit, faEllipsisH, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Nav, Card, Button, Table, Dropdown, Pagination, ButtonGroup, Col, Row, Form, Breadcrumb, InputGroup, Modal} from '@themesberg/react-bootstrap';
import Preloader from "../components/Preloader";
import { Link, useHistory } from 'react-router-dom';
import { Routes } from "../routes";
import transactions from "../data/transactions";
import axios from 'axios';
import Swal from 'sweetalert2';
import Loader from "../components/Loader";



const ValueChange = ({ value, suffix }) => {

	const valueIcon = value < 0 ? faAngleDown : faAngleUp;
	const valueTxtColor = value < 0 ? "text-danger" : "text-success";

	return (
		value ? <span className={valueTxtColor}>
			<FontAwesomeIcon icon={valueIcon} />
			<span className="fw-bold ms-1">
				{Math.abs(value)}{suffix}
			</span>
		</span> : "--"
	);
};







export const EmailTemplateTable = (searchKey) => {



	const [showDefault, setShowDefault] = useState(false);
	const handleClose = () => setShowDefault(false);


	const [data, setData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [startPage, setStartPage] = useState(1);
	const [endPage, setEndPage] = useState(1);
	const [totalRecord, setTotalRecord] = useState(1);
	const [perPage, setPerPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [search, setSearch] = useState("");
	const [loading, setLoading] = useState(true);
	const [sortBy, setSortBy] = useState("id");
	const [orderBy, setOrderBy] = useState("asc");

	{/*Sorting*/ }
	const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });


	const handleSort = (key) => {
		let direction = 'asc';
		if (sortConfig.key === key && sortConfig.direction === 'asc') {
			direction = 'desc';
		}

		setSortConfig({ key, direction });
		
		const storedUser = localStorage.getItem('user');
		const userObject = JSON.parse(storedUser);
		const accessToken = userObject.access_token;
		const user_id = userObject.user_id;

		const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/email_template';
		
		setSortBy(key);
		setOrderBy(direction);

		const formData = new FormData();
		formData.append('page', 1);
		formData.append('search', search);
		formData.append('sortBy', key);
		formData.append('orderBy', direction);
		setLoading(true);


		axios.post(apiBaseURL, formData, {
			headers: {
				Authorization: `Bearer ${accessToken}`, // Include the access token in the Authorization header
			}
		}).then(({ data }) => {

				if (data.status === "success") {
					setData(data.data.data);
					setCurrentPage(data.data.current_page);
					setTotalRecord(data.data.total);
					setPerPage(data.data.per_page);
					setStartPage(data.data.per_page);
					setEndPage(data.data.last_page);

					var totalPages = Math.ceil(data.data.total / data.data.per_page);
					setTotalPages(totalPages);
				}
				setLoading(false);

			}).catch(error => {
				setLoading(false);
			});

	};





	const getSortIcon = (columnKey) => {
		if (sortConfig.key === columnKey) {
			return sortConfig.direction === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
		}
		return <FontAwesomeIcon icon={faSort} />;
	};

	{/*End Sorting*/ }





	const fetchData = async page => {
		const storedUser = localStorage.getItem('user');
		const userObject = JSON.parse(storedUser);
		const accessToken = userObject.access_token;

		const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/email_template';
		const formData = new FormData();
		formData.append('page', page);
		formData.append('search', search);
		formData.append('sortBy', sortBy);
		formData.append('orderBy', orderBy);

		setLoading(true);

		axios.post(apiBaseURL, formData, {
			headers: {
				Authorization: `Bearer ${accessToken}`, // Include the access token in the Authorization header
			}
		}).then(({ data }) => {
				if (data.status === "success") {
					setData(data.data.data);
					setCurrentPage(data.data.current_page);
					setTotalRecord(data.data.total);
					setPerPage(data.data.per_page);
					setStartPage(data.data.per_page);
					setEndPage(data.data.last_page);

					var totalPages = Math.ceil(data.data.total / data.data.per_page);
					setTotalPages(totalPages);
				}
				setLoading(false);
		}).catch(error => {
			setLoading(false);
		});

	};



	
	useEffect(() => {
		setSearch(searchKey?.data ? searchKey.data : ""); // Set search as empty if searchKey.data is blank
	}, [searchKey?.data]);

	useEffect(() => {
		fetchData(currentPage);
	}, [search]);


	


	const handlePageChange = page => {
		setCurrentPage(page);
	};


	const onPageChange = (pageNo) => {
		setCurrentPage(pageNo);
		fetchData(pageNo);
	};
	
	
	
	

	/*Email Template Edit*/
	const storedUser = localStorage.getItem('user');
	const userObject = JSON.parse(storedUser);
	const accessToken = userObject.access_token;

	const [formData, setFormData] = useState({
		id:'',
		Title: '',
		subject: '',
		message: '',
		reminder_time: '',
		status : ''
	});

	const [errors, setErrors] = useState({
		title: '',
		subject: '',
		message: '',
		status: ''
	});
  
	const [invalid, setInvalid] = useState(false);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({
		  ...formData,
		  [name]: value,
		});
	};
	
	const editTemaplte = (props) => {
		let tempData = 	{
							id: props.id || '',
							Title: props.Title || '',
							subject: props.subject || '',
							message: props.message || '',
							reminder_time: props.reminder_time || '',
							status: props.status || '',
						};
									
		setFormData(tempData);
		
		setShowDefault(true);
	}
	
  
	const handleSubmit = (e) => {
		e.preventDefault();
		// Validate email and password
		let valid = true;
		const newErrors = {
			Title: '',
			subject: '',
			message: '',
		};

		
		if(formData.Title === ""){
			newErrors.title = 'Title field is required';
			valid = false;
		}
		if(formData.subject === ""){
			newErrors.subject = 'Subject field is required';
			valid = false;
		}
		if(formData.message === ""){
			newErrors.message = 'Message field is required';
			valid = false;
		}
		if(formData.status === ""){
			newErrors.message = 'Select teamplete status';
			valid = false;
		}
		
		if (formData.reminder_time !== "") {
			if (isNaN(formData.reminder_time)) {
				newErrors.reminder_time = 'Reminder time must be a number';
				valid = false;
			} else if (Number(formData.reminder_time) < 0) {
				newErrors.reminder_time = 'Reminder time cannot be negative';
				valid = false;
			} else if (!Number.isInteger(Number(formData.reminder_time))) {
				newErrors.reminder_time = 'Reminder time must be a whole number';
				valid = false;
			}
		}


		
		setErrors(newErrors);

		if (valid) {
			setLoading(true);
			const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/email_template/create'; 
			
			axios.post(apiBaseURL, formData, {
			  headers: {
				Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header
			  }
			})
			.then(({ data }) => {
				if(data.status==="success"){
					setShowDefault(false);
				}
				setLoading(false);
				window.location.reload();
			}).catch(error => {
				setLoading(false);
			});
			
		}
	};
	/*End Email Template Edit*/



	if (loading) {
		//return <div>Loading...</div>;
	}



	const items = [];

	for (let i = 0; i < totalPages; i++) {
		items.push(i + 1);
	}


	const totalUsers = data.length;
	const srStart = (currentPage - 1) * perPage;
	const modifiedData = data.map((t, index) => ({
		...t,
		serialNumber: srStart + index + 1 // Assuming the serial number starts from 1
	}));

	const prevPage = currentPage > 1 ? currentPage - 1 : 1;
	const nextPage = currentPage < totalPages ? currentPage + 1 : totalPages;


	const TableRow = (props) => {
		const { id, Title, reminder_time, created_at } = props;
		const storedUser = localStorage.getItem('user');
		const userObject = JSON.parse(storedUser);
		const accessToken = userObject.access_token;
		const history = useHistory();

		return (

			<tr>
				<td>
					<span className="fw-normal">
						{id}
					</span>
				</td>

				<td>
					<span className="fw-normal">
						{Title}
					</span>
				</td>
				
				<td>
					<span className="fw-normal">
					{reminder_time === null ? "N/A" : reminder_time}
					</span>
				</td>

				<td>
					<Dropdown as={ButtonGroup}>
						<Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
							<span className="icon icon-sm">
								<FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
							</span>
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Item className="text-primary" onClick={() => editTemaplte(props)}>
								<FontAwesomeIcon icon={faEdit} className="me-2"  />Edit
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</td>
			</tr>

		);

	};



	return (
		<>
			{loading && <Loader />}

			<Card border="light" className="table-wrapper table-responsive shadow-sm">
				<Card.Body className="pt-0">
					<Table hover className="user-table align-items-center">
						<thead>
							<tr>
								<th className="border-bottom" onClick={() => handleSort('id')}>Template Id </th>
								<th className="border-bottom" onClick={() => handleSort('Title')}>Title {getSortIcon('Title')}</th>
								<th className="border-bottom" onClick={() => handleSort('reminder_time')}>Reminder Time (Hours)</th>
								<th className="border-bottom" >Action</th>
							</tr>
						</thead>

						<tbody>
						  {modifiedData.length === 0 ? (
							<tr>
							  <td colSpan="7" className="text-center">No Record Found</td>
							</tr>
						  ) : (
							modifiedData.map(t => <TableRow key={`data-${t.id}`} {...t} />)
						  )}
						</tbody>
					</Table>

					<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
						<Nav>
							<Pagination className="mb-2 mb-lg-0">
								<Pagination.Prev onClick={() => onPageChange(prevPage)}>
									Previous
								</Pagination.Prev>

								{items.map((item, index) => (
									<Pagination.Item active={item === currentPage} onClick={() => onPageChange(item)}>{item}</Pagination.Item>
								))}

								<Pagination.Next onClick={() => onPageChange(nextPage)}>
									Next
								</Pagination.Next>
							</Pagination>
						</Nav>

						<small className="fw-bold">
							Showing <b>{totalUsers}</b> out of <b>{totalRecord}</b> entries
						</small>
					</Card.Footer>
				</Card.Body>
			</Card>
			
			
			<React.Fragment>
			  <Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
				<Modal.Header>
				  <Modal.Title className="h6">Edit Email Template</Modal.Title>
				  <Button variant="close" aria-label="Close" onClick={handleClose} />
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col xs={12} xl={12}>
							<Card border="light" className="bg-white mb-4">
							  <Card.Body>
								{/* <h5 className="mb-4">Add Question</h5> */}
								<Form onSubmit={handleSubmit}>
								  <Row>
								  
									<Col md={12} className="mb-3">
									  <Form.Group id="Title">
										<Form.Label>Title <span className="error-message">*</span></Form.Label>
										<Form.Control type="text" name="Title" placeholder="Title" value={formData.Title} onChange={handleInputChange} />
									  </Form.Group>
									  <div className="error-message">{errors.title}</div>
									</Col>
									
									<Col md={12} className="mb-3">
									  <Form.Group id="Subject">
										<Form.Label>Subject <span className="error-message">*</span></Form.Label>
										<Form.Control type="text" name="subject" placeholder="Subject" value={formData.subject} onChange={handleInputChange} />
									  </Form.Group>
									  <div className="error-message">{errors.subject}</div>
									</Col>
									
									
									<Col md={12} className="mb-3">
									  <Form.Group id="Subject">
										<Form.Label>Message Body <span className="error-message">*</span></Form.Label>
										<Form.Control as="textarea" name="message" rows={20} value={formData.message}  placeholder="Message Body" onChange={handleInputChange} />
									  </Form.Group>
									  <div className="error-message">{errors.message}</div>
									</Col>
									
									<Col md={12} className="mb-3">
									  <Form.Group id="reminder_time">
										<Form.Label>Reminder Time (Hours)</Form.Label>
										<Form.Control type="text" name="reminder_time" placeholder="Reminder Time" value={formData.reminder_time} onChange={handleInputChange} />
									  </Form.Group>
									  <div className="error-message">{errors.reminder_time}</div>
									</Col>
									
									<Col md={12} className="mb-3">
									  <Form.Group id="status">
										<Form.Label>
										  Status <span className="error-message">*</span>
										</Form.Label>
										<Form.Select name="status" onChange={handleInputChange}>
										  <option key="1" value="1" selected={formData.status == "1"}>
											Enable
										  </option>
										  <option key="0" value="0" selected={formData.status == "0"}>
											Disable
										  </option>
										</Form.Select>
									  </Form.Group>
									</Col>

									
								  </Row>
								  
								  <div className="mt-3 center">
									<Button variant="primary" type="submit">Save</Button>
								  </div>
								</Form>
							  </Card.Body>
							</Card>
						</Col>
					  </Row>
				</Modal.Body>
			  </Modal>
			</React.Fragment>

		</>
	);

};